
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ApiIntegration } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";

export default defineComponent({
  name: "document-center-invoice-verification-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref({
      document_time_start: "",
      document_time_end: "",
      report_kw: "",
      report_year: "",
      report_type: "",
      status: [15],
    });

    const options = ref({
      typeLoading: false,
      typeOptions: [] as any[],
      yearLoading: false,
      kwOptions: [] as any[],
      yearOptions: [] as any[],
      statusOptions: [] as TaggingItem[],
    });

    const getDatevReportOption = async () => {
      options.value.typeLoading = true;
      const { data } = await ApiIntegration.getDatevReportOption({});
      options.value.typeLoading = false;
      if (data.code == 0) {
        options.value.typeOptions = data.data;
      }
    };

    const getDatevReportYearOption = async () => {
      options.value.yearLoading = true;
      const { data } = await ApiIntegration.getDatevReportYearOption({});
      options.value.yearLoading = false;
      if (data.code == 0) {
        options.value.kwOptions = data.data.kw;
        options.value.yearOptions = data.data.year;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getDatevReportOption();
      getDatevReportYearOption();
      options.value.statusOptions = [
        {
          value: 10,
          label: t("datevBalance.Verified"),
        },
        {
          value: 15,
          label: t("datevBalance.Pending"),
        },
      ];
    });

    return {
      t,
      formData,
      formRef,
      options,
      submit,
      handleReset,
    };
  },
});
